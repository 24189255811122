window.FormValidator = {
  validateField(element, errorMessage) {
    if (!element.validity.valid || errorMessage) {
      const message = errorMessage || element.validationMessage;
      const hasError = element.nextSibling?.className?.includes('invalid-feedback');
      const invalidUrlHint = 'Please enter a valid URL (e.g.: https://example.com).';
      if (!hasError) {
        const error = document.createElement('div');
        error.classList.add('invalid-feedback');
        error.innerText = element.validity.patternMismatch ? invalidUrlHint : message;
        element.parentNode.insertBefore(error, element.nextSibling);
      } else if (hasError && message !== element.nextSibling.innerText) {
        element.nextSibling.innerText = element.validity.patternMismatch ? invalidUrlHint : element.validationMessage;
      }
      element.classList.remove('is-valid');
      element.classList.add('is-invalid');
    } else {
      element.classList.remove('is-invalid');
      element.classList.add('is-valid');
      if (element.nextSibling?.className?.includes('invalid-feedback')) {
        element.parentNode.removeChild(element.nextSibling);
      }
    }
  },
  validateImageAttachment(element, errorMessage) {
    if (errorMessage.length > 0) {
      const hasError = element.nextSibling?.className?.includes('invalid-feedback');
      if (!hasError) {
        const error = document.createElement('div');
        error.classList.add('invalid-feedback');
        error.innerText = errorMessage;
        element.parentNode.insertBefore(error, element.nextSibling);
      } else if (hasError && errorMessage !== element.nextSibling.innerText) {
        element.nextSibling.innerText = errorMessage;
      }
      element.classList.remove('is-valid');
      element.classList.add('is-invalid');
    } else {
      element.classList.remove('is-invalid');
      element.classList.add('is-valid');
      if (element.nextSibling?.className?.includes('invalid-feedback')) {
        element.parentNode.removeChild(element.nextSibling);
      }
    }
  }
};
