'use strict';

import { Turbo } from "@hotwired/turbo-rails";
import Cookie from "js.cookie";

function CookiesConsent() {
  this.init = function() {
    const cookiesAgreeButton = document.querySelector('.js-cookies-agree');
    const cookiesDisagreeButton = document.querySelector('.js-cookies-disagree');

    if (cookiesAgreeButton) {
      cookiesAgreeButton.addEventListener('click', this.setCookie);
      // clear turbolinks cache so cookie banner does not reappear
      Turbo.clearCache();
    }

    if (cookiesDisagreeButton) {
      cookiesDisagreeButton.addEventListener('click', this.removeCookies);
      Turbo.clearCache();
    }
  };

  this.setCookie = () => {
    Cookie.set('cookie_eu_consented', true, { path: '/', expires: 365, secure: true });
    this.dismissBanner();
  };

  this.removeCookies = () => {
    this.dismissBanner();
    document.body.innerHTML = "<p>In order to use our service we require cookies. Without your consent we're unable to go any further than this.</p>" +
        "<p>You can always come back or refresh the page and give your consent for our cookies.</p>";
  };

  this.dismissBanner = function() {
    const container = document.querySelector('.js-cookies-banner');
    container.parentNode.removeChild(container);
  };
}

document.addEventListener("turbo:load", () => {
  const cookiesConsent = new CookiesConsent();
  cookiesConsent.init();
});
