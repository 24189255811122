require("@rails/ujs").start()
require("@hotwired/turbo-rails")
require("@rails/activestorage").start()
require('js.cookie')
require('trix')
require("@rails/actiontext")

import 'trix_overrides';
import 'trix_extensions';
import 'cookies'

Array.prototype.searchFor = function(candid) {
  return this.filter((item) => {
    return item.name.toLowerCase().includes(candid.toLowerCase());
  });
};

document.addEventListener("turbo:load", () => {
  let menuToggler = document.querySelector('button.navbar-toggler')
  if (menuToggler) {
    menuToggler.addEventListener('click', function(e) {
      document.querySelector(`${e.currentTarget.dataset.target}`).classList.toggle('show')
    });
  }
});

