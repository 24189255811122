window.addEventListener("trix-file-accept", function(event) {
  if (event.target.id !== 'blog_body' && event.target.id !== 'company-trix') {
    event.preventDefault();
  }
});

window.addEventListener("trix-change", function(event) {
  handleTextAreaChange(document.querySelector("trix-editor#job-post-trix"), document.querySelector('#job_description')?.value, true)
});

function handleTextAreaChange(trixElement, trixEditorValue, validate = false) {
  if (trixElement) {
    // Handle input validation errors
    if (!validate)
      return;

    let submitBtn = document.querySelector('input[type=submit]');
    if (trixEditorValue) {
      trixElement.classList.remove('is-invalid');
      trixElement.classList.add('is-valid');
      submitBtn.disabled = false;
    } else {
      trixElement.classList.remove('is-valid');
      trixElement.classList.add('is-invalid');
      submitBtn.disabled = true;
    }
  }
};
