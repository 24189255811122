import '../utils/validations';
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'form',
    'jobDescriptionEditor'
  ];

  connect() {
    this.timeout = null;
  }

  validateInput(event) {
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      window.FormValidator.validateField(event.target);
    }, 600);
  };

  onSubmit(event) {
    event.preventDefault();
    const jobDescriptionValue = this.formTarget.querySelector('#job_description').value
    document.querySelectorAll("input:invalid, textarea:invalid, select:invalid").forEach((element) => {
      window.FormValidator.validateField(element);
    });
    if (this.formTarget.checkValidity() && jobDescriptionValue) {
      this.jobDescriptionEditorTarget.classList.remove('is-invalid');
      this.jobDescriptionEditorTarget.classList.add('is-valid');

      // Submit the job post form
      this.formTarget.submit();
    } else {
      if (!this.formTarget.className.includes('was-validated')) {
        this.formTarget.classList.add('was-validated');
      }

      if (!jobDescriptionValue) {
        this.jobDescriptionEditorTarget.classList.remove('is-valid');
        this.jobDescriptionEditorTarget.classList.add('is-invalid');
      }
    }
  }
}
