import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    publishableKey: String,
    planId: String,
    createCheckoutUrl: String
  }

  initialize() {
    let customerId;
  }

  connect() {
    if (this.hasPublishableKeyValue) {
      this.csrfToken = document.querySelector('[name="csrf-token"]').content;
      this.stripe = Stripe(this.publishableKeyValue);
    }
  }

  disconnect() {
    this.csrfToken = null;
    this.stripe = null;
  }

  handleFetchResult(result) {
    const self = this;
    if (!result.ok) {
      return result.json().then(function(json) {
        if (json.error && json.error.message) {
          throw new Error(result.url + ' ' + result.status + ' ' + json.error.message);
        }
      }).catch(function(err) {
        let errorEl = document.getElementById("error-message")
        errorEl.textContent = err;
        errorEl.style.display = "block";
        throw err;
      });
    }
    return result.json();
  };

  // Handle any errors returned from Checkout
  handleResult(result) {
    if (result.error) {
      this.showErrorMessage(result.error.message);
    }
  };

  showErrorMessage(message) {
    let errorEl = document.getElementById("error-message")
    errorEl.textContent = message;
    errorEl.style.display = "block";
  };

  stripeCheckout(e) {
    e.preventDefault();
    const self = this;
    fetch(this.createCheckoutUrlValue, {
      method: "POST",
      headers: {
        "X-CSRF-Token": self.csrfToken
      },
      body: JSON.stringify({
        planId: self.planIdValue
      })
    }).then(this.handleFetchResult)
        .then(function(data) {
          // Call Stripe.js method to redirect to the new Checkout page
          self.stripe.redirectToCheckout({
            sessionId: data.sessionId
          }).then(self.handleResult);
        });
  }
}
