import { Controller } from 'stimulus';
import bsCustomFileInput from 'bs-custom-file-input';

export default class extends Controller {
  static values = { vatIdCheckTimeout: Number, vatApiKey: String }
  static targets = ['vatId', 'loadingVatId', 'submitFormBtn'];

  initialize() {
    bsCustomFileInput.init();
  }

  connect() {
    this.submitFormBtnTarget.disabled = false;
    this.csrfToken = document.querySelector('[name="csrf-token"]').content;
  }

  disconnect() {
    this.csrfToken = null;
  }

  handleSubmitBtnState() {
    const helpTextTarget = this.vatIdTarget.nextElementSibling.nextElementSibling
    this.vatIdTarget.classList.remove('is-invalid');
    this.vatIdTarget.classList.remove('is-valid');
    helpTextTarget.classList.remove('text-danger');
    helpTextTarget.innerText = 'Within the EU, we collect VAT on any direct-to-consumer sales to EU-based customers. If you have a valid VAT identification number we will apply a reverse charge for VAT to your invoice.'

    if(this.checkTimer) {
      clearInterval(this.checkTimer);
    }
    const value = this.vatIdTarget.value
    const regex = /([A-Za-z]{2})(\d+)/
    this.submitFormBtnTarget.disabled = true;
    if (value.length > 0) {
      this.loadingVatIdTarget.style.display = 'block';
      this.checkTimer = setInterval(() => {
        if (value.match(regex)) {
          fetch(`https://vat.abstractapi.com/v1/validate/?api_key=${this.vatApiKeyValue}&vat_number=${value}`)
              .then((result) => {
                clearInterval(this.checkTimer);
                return result.json()
              })
              .then((vatData) => {
                if (vatData.valid) {
                  this.vatIdTarget.classList.remove('is-invalid');
                  this.vatIdTarget.classList.add('is-valid');
                  helpTextTarget.classList.remove('text-danger');
                  helpTextTarget.classList.add('text-muted');
                  helpTextTarget.innerText = 'Within the EU, we collect VAT on any direct-to-consumer sales to EU-based customers. If you have a valid VAT identification number we will apply a reverse charge for VAT to your invoice.'
                  this.submitFormBtnTarget.disabled = false;
                } else {
                  this.vatIdTarget.classList.remove('is-valid');
                  this.vatIdTarget.classList.add('is-invalid');
                  helpTextTarget.classList.remove('text-muted');
                  helpTextTarget.classList.add('text-danger');
                  helpTextTarget.innerText = 'VAT ID is invalid. Please try a different one or contact our support team.'
                }
              })
        } else {
          // invalid VAT ID format
          this.vatIdTarget.classList.remove('is-valid');
          this.vatIdTarget.classList.add('is-invalid');
          helpTextTarget.classList.remove('text-muted');
          helpTextTarget.classList.add('text-danger');
          helpTextTarget.innerText = 'VAT ID format is invalid. It should contain the country code followed by a number (e.g.: AU12345678).'
        }
        this.loadingVatIdTarget.style.display = 'none';
      }, this.vatIdCheckTimeoutValue)
    } else {
      this.submitFormBtnTarget.disabled = false;
    }
  }
}
